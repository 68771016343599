/*
color-palette:
darker lavender: #6C72FF;
lighter lavender: #888EFF;
darker gray: #99A3B0;
lighter gray: #C4C4C4;
white: #ffffff;

*/

html, body {
  margin: 0;
  height: 100%;
  font-family: "San Francisco";
}

@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  font-style: normal;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

@font-face {
  font-family: "San Francisco bold";
  font-weight: 400;
  font-style: bold;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff");
}

/* @font-face {
  font-family:'SF Pro Display';
  font-style:normal;
  font-weight:100;
  src:local('☺︎'), url("http://www.apple.com/wss/fonts/SF-Pro-Display/v1/sf-pro-display_ultralight.woff") format("woff"), url("http://www.apple.com/wss/fonts/SF-Pro-Display/v1/sf-pro-display_ultralight.ttf") format("truetype");
} 
*/


/* @font-face {
  font-family:'SF Pro Text';
  font-style:normal;
  font-weight:100;
  src:local('☺︎'), url("http://www.apple.com/wss/fonts/SF-Pro-Text/v1/sf-pro-text_ultralight.woff") format("woff"), url("http://www.apple.com/wss/fonts/SF-Pro-Text/v1/sf-pro-text_ultralight.ttf") format("truetype");
} */


div, body, h1 {
  font-family: "San Francisco", "Helvetica", "Arial" !important;
}
/* body {
  background-color: black;
} */

.location_request_info{
  padding: 4vh 0.5vw 1.5vh 0.5vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.location_request_info h1{
  font-weight: 900;
  font-size: 32px;
  letter-spacing: 0.25px;
  width: 100%;
  padding-top: 1.25vh;
  padding-bottom: 1.25vh;
}
.location_request_info.steps_title h1 {
  font-size: 29px !important;
}
.location_request_info h2{
  font-weight: 700;
  font-size: 22px;
  padding-bottom: 1.25vh;
}
.location_request_explanation{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3vh;
  margin-bottom: 3vh;
  margin-left: 4px;
  margin-right: 4px;
  padding: 1.5vh 1vw 1.5vh 1vw;
  background-color: #3a3e9c;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.location_request_info h3{
  font-weight: normal;
  font-size: 20px;
  width: 102%;
  color: #ffffff;
}
.location_request_info h4{
  padding-top: 1.25vh;
  font-weight: normal;
  font-size: 2.25vh;
}
.send_prefs_button{
  margin: auto;
}
.location_request_button{
  color: #3a3e9c !important;
  background-color: #fff !important;
  font-size: 21px !important;
  font-weight: 900 !important;
  display: inline;
  white-space: nowrap;
  margin: auto;
  width: auto;
  height: 80%;
  border-radius: 12px !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.75em !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
.login_request_button{
  color: #6C72FF !important;
  background-color: #FFFFFF !important;
  display: inline;
  white-space: nowrap;
  /* width: 50%; */
  height: 80%;

  margin-left: 1% !important;
  margin-right: 5px !important;

  padding-left: 5% !important;
  padding-right: 3% !important;

  font-size: 14px !important;
  /* min-width: 60px !important; */

}
.remote_bar {
  width: 100%;
  position: fixed;
  display: block;

  height: 10%;
  background-color: #5a5fd6; 
  /* background-color: #6C72FF; */
  color: white;
  font-family: "San Francisco", "Helvetica", "Arial";
  /* border-radius: 0px 0px 60px 60px; */
  border-radius: 25px;
  vertical-align: top;
  
  /* text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center; */

  margin-top: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-bottom: 0.5vh;

  z-index: 99;
}

.f_remote_bar {
  position: fixed;
  top: 4px;
  left: 0;  
  /* z-index: 999; */
  width: 100%;
  height: 100vh;

  /* height: 100%; */
  background-color: #6C72FF;
  color: white;
  font-family: "San Francisco", Helvetica, Arial;
  /* border-radius: 20px 60px 20px; */
  border-radius: 50px 50px 0px 0px;
  vertical-align: top;
}
.logo_container{
  margin-top: 28px;
  display: flex;
  justify-content: center;
}
.strive_logo{
  margin-left: 10px;
  width: 96%;
  /* height: 80px; */
}
.nav_top {
  display: flex;
 /* max-height: 56%; */
 height: 70%;
  /* padding-top: 3%; */
  /* padding-right: 6%; */
  margin-top: 2.5%;
}
.nav_spacer {
  width: 52%;
}
.nav_spacer_connect {
  width: 50%;
}
.intro {
  font-family: "San Francisco bold", Helvetica, Arial !important;
  font-style: bold;
  text-align: left;
  padding-top: 2.5vh;
  padding-left: 2.5vh;
  padding-right: auto;

  font-style: bold;
  font-size: 2.25vh;
}

.f_intro {
  font-family: "San Francisco bold", Helvetica, Arial;
  font-style: bold;
  text-align: center;
  padding-top: 35px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bolder;
  font-size: 2em;
}

.instructions {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1.5vh;
}

.f_instructions {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 1.5em;
}

.tabs {
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 11vh;
  text-align: center;
}
.MuiTabs-root {
  min-height: 40px !important;
}
.nav_tabs {
  z-index: 201;
  width: 100%;
}
.all_tabs{
  display: flex;
  flex-direction: column;

}
.tab_panel {
  margin-left: -60% !important;
  margin-top: 12% !important;
  width: 100% !important;

  padding-left: 2% !important;
  padding-right: 4% !important;

}
.tab_panel_2 {
  margin-left: -41%!important;
  margin-top: 14% !important;
  /* width: 60% !important; */
}

.tab {
  background-color: #6C72FF;
  border-radius: 60px;
  /* background: #a8a9f7; */
  width: 100%;

  box-shadow: 5px 5px 5px rgb(0 0 0 / 33%);

}
.queue_container {
  text-align: center;
  max-height: 350px !important;
  /* width: 70%; */
  /* min-width: 158%;  */
  /* overflow: auto; */
}
.table_container {
  text-align: center;
  max-height: 550px !important;
  /* width: 70%; */
  /* min-width: 158%;  */
  /* overflow: auto; */
}

.failed_tab {
  /* background-color: #6C72FF; */
  border-radius: 60px;
  /* margin-top: 50%!important;
  margin-right: 27%; */
    /* background: #a8a9f7; */
}

.tab_label {
  font-family: "San Francisco", Helvetica, Arial !important;
  font-size: 1em !important;
  font-weight: bolder !important;
}

/* OVERRIDING Material UI Elements */

.jss7{
  max-width: 540px !important;
}
/* transparent genre items */
/* .genre_container.jss8 {
  background-color: transparent !important;
} */

.MuiBox-root {
  /* padding-top: 12px !important; */
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.MuiIconButton-root {
  padding: 0px !important;
}

.MuiList-root {
  padding: none !important;
  margin: none !important;
}
.MuiListItemIcon-root {
  min-width: 20px !important;
}
.MuiListItemText-root {
  min-width: 75px !important;
  
}
.MuiExpansionPanelSummary-root {
  height: 104px !important;
}

.song_artist_block .MuiTypography-displayBlock {
  display: block;
  /* height: 25px; */
  width: 60vw;
  overflow-y: auto;
}
/* GENRE List Items */
.MuiListItem-gutters{
  /* padding-left: 25px !important; */
  padding-right: 16px;
}
.MuiListItem-secondaryAction {
  padding-right: none;
}

.MuiListItemText-multiline {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 6px;
/* comment out width when setting genres vertical scroll on right side of tracks instead of flex inline genres */
  /* width: 50%;  */
}

/* actual genre text */
.MuiTypography-root.MuiListItemText-primary.MuiTypography-body2.MuiTypography-displayBlock{
  color: #252636;
  font-family: "San Francisco", 'Helvetica', 'Arial';
  font-weight: bold;
  border-top: #6C72FF;
}
.genre_div{
  text-align: center;
}

.genre_container{
  text-align: center;
  max-height: 275px !important;
  display: flex;
  overflow: scroll;
  margin-top: 2px;

  background-color: transparent !important;


}
.genre_vote_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #a1a4ff;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  margin-right: 4px;
}

.genre_vote_button:hover {
  background-color: #898df9;
}

.genre_vote_button:active {
  background-color: #6C72FF;
}

.genre_vote_button .material-icons {
  color: white;
  font-size: 24px;
}

.upcoming_song {
  margin-top: 5px;
  margin-bottom: 5px;
  
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;

  text-align: left !important;
  background-color: #6C72FF;
  width: 46%;
  border-radius: 24px;
  height: 15%;
  color: white;

  box-shadow: 0px 5px 4px rgb(0 0 0 / 25%);
}
.request_song {
  text-align: left !important;
  display: flex;
}

.upcoming_queue {
  max-height: 30%;
}
.genres{
  width: 88px !important;
  border-radius: 20px !important;
  height: 4vh;
  font-family: "San Francisco", 'Helvetica', 'Arial' !important;
  margin-bottom: 6px !important;
  margin-right: 1px !important;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.19);
}
.tip_div {
  /* margin-left: -37%!important; */
  height: 80% !important;
}
.tip_button{
  color: #FFFFFF !important;
  width: 12%;
  margin-left: 5px !important;
  margin-right: 5px !important;
  overflow: hidden !important;
}

.tip-menu-button {
  display: flex !important;
  /* flex-direction: column !important; */
  background-color: #6C72FF !important;
  max-width: 69px !important;
  height: 100% !important;
  /* margin-left: 5px !important; */
  margin-right: 5px !important;

}

.MuiIconButton-colorSecondary {
  color: #6C72FF !important;;
}
/* .MuiIconButton-label {
  color: #6C72FF !important;
} */

.tool_button {
  margin: 10px !important;
}
/* REMOTE */

.remote {
  height: 20vh;
  background-color: #6C72FF;
  color: white;
  font-family: "San Francisco", 'Helvetica', 'Arial';
  border-radius: 25px;
  display: inline;
  justify-content: center;
  vertical-align: middle;
  /* margin-left: 15px;
  margin-right: 15px; */
  position: fixed;
  bottom: 15px;
  /* left: 0; */
  width: 100%;
  z-index: 100;

}
.toast {
  z-index: 400  !important;
  top: 18vh !important;
  position: inherit !important;
}

.remote_track_info {
  display: flex;
  max-height: 12.5vh;
  width: 94%;
  padding-top: 0.75em;
}

/*Remote Buttons*/
.track_info{
  justify-content: center;
  padding-right: 1vh;
  padding-left: 2vh;

  font-family: "San Francisco","Helvetica","Arial";
  font-weight: bold;
  color: #6C72FF;

  line-height: 1em;
  max-height: 2.8em;
/* 
  width: 200px;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis; */
}

.track_info h2{
  margin-top: auto;
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 4vw;

  width: 85px;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;

  display: inline-block;

}

.expand_panels {
  width: 100% !important;
  background-color: #e2e3f2 !important;
}

.ellipsis::after {
  content: "...";
  position: absolute;
  right: -12px; 
  bottom: 5px;
}

.remote_top{
  display: flex;
  padding-top: 5px;
  margin-right: 2vh;
  margin-left: 2vh;
}
.remote_bottom{
  display: flex;
  padding-bottom: 6px;
  padding-top: 5px;

}
.track_scrolling_view{
  color: #6C72FF !important;
  margin-top: 10px;
  margin-left: 1vh;
  margin-bottom: auto;
  font-size: 2.4vh;
  max-width: 75%;
  display: inline;
  font-family: 'San Francisco bold' !important;
}

.remote_buttons_div {
  position: relative;
  /* display: flex; */
  justify-content: center;
  border-radius: 25px;
  vertical-align: middle;
  background-color: #ffffff;
  width: 80%;
  height: 85%;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;

  box-shadow: 5px 8px 5px rgb(0 0 0 / 33%);
}

.buttons {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 2vh;
}

.pause_icon {
  /* width: 20px;
  height:20px; */
  padding-right: 10px;
  padding-left: 10px;
}

.skip_icon {
  /* width: 20px;
  height:20px; */
  padding-left: 15px;
  padding-right: 20px;

}

/* Track styling */
.profile_button {
  display: grid !important;
  border-radius: 24px !important;
  width: 10%;
  /* height: 92%; */
  z-index: 101;

  /* padding-top: 1% !important; */
  /* margin-top: -8px !important; */
  margin-left: 3px !important;
  margin-right: 3px !important;
}
.profile_button_connect {
  display: grid !important;
  border-radius: 24px !important;
  width: 13%;
  /* height: 92%; */
  z-index: 101;

  /* margin-top: -8px !important; */
  margin-left: 5px !important;
  margin-right: 5px !important;
  
  min-width: unset !important;
}
.pax_names {
  margin-top: -3px;
  margin-bottom: 2px;
  font-size: 13px !important;
  font-weight: bold !important;
}
.menu-button {
  width: 20%;
}
.hamburger {
  width: 14%;
  height: 80%;
  min-width: 54px !important;
  margin-right: 10px !important;
  /* margin-left: 16% !important; */
  /* margin-top: 5px !important; */

}

.logout_button {
  color: rgb(255, 73, 73) !important;
}
.logout_icon {
  color: rgb(255, 73, 73);;
}
.spotify_auth_icon {
  color: #ffffff !important;
  width: 100px !important;
  height: 50px !important;
  border: none;
  margin: 10px !important;

}
.spotify_icon_small {
  /* color: #ffffff !important; */
  width: 92% !important;
  height: 125% !important;
  border: none;
  /* margin: 4px !important; */
  border-radius: 24px !important;
}
.spotify_icon_nav {
  /* color: #ffffff !important; */
  width: 50px !important;
  height: 50px !important;
  border: none;
  /* margin: 4px !important; */
  border-radius: 20px !important;
}
.spotify_icon_login {
  /* color: #ffffff !important; */
  width: 75% !important;
  /* height: 125% !important; */
  border: none;
  /* margin: 4px !important; */
  border-radius: 24px !important;
}
.auth_icon_small {
  /* color: #ffffff !important; */
  width: 100% !important;
  height: 115% !important;
  border: none;
  /* margin: 4px !important; */
  border-radius: 24px !important;
}

.auth_icon_nav {
  /* color: #ffffff !important; */
  width: 50px !important;
  height: 50px !important;
  border: none;
  /* margin: 4px !important; */
  border-radius: 18px !important;
}


/* TABBAR ELEMENTS */
.MuiAppBar-root {
  /* width: 47% !important; */
  /* margin-top: -6%;
  margin-left: 33%; */
}


.spotify_icon {
  width: 36px;
  height: 36px;
}

.cover_art_icon {
  width: 62px;
  height: 62px;
  border-radius: 15px;
}

.current_art {
  width: 120px;
  height: 120px;
  border-radius: 35px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px;
}

/*List/Table view*/
.table_div{
  /* background-color: rgba(255, 255, 255, 0.2) !important; */
  
  /* min-width: 160%; */
}
.queue_list_view {
  max-height: 26vh;
  overflow: auto;
}
.list_view {
  max-height: 69vh;
  overflow: auto;
  width: 110%;
  margin-left: 30% !important;
}
.list_view_f {
  max-height: 69vh;
  overflow: auto;
  width: 100%;
  /* background-color: #e9eaff !important; */
  /* margin-left
  : -14%; */
}

.list_view_f .MuiPaper-root {
  background-color: #b1b3da !important;
}
.MuiListItem-root {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.MuiTypography-body1 {
  font-weight: 800 !important;
}
.loading_view {
  display: flex;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.ig_icon {
  font-size: 28px !important;
}

#title {
  font-style: bold;
  font-size: 1.5em;
  text-align: center;
  padding-top: 15px;
}

.anchor {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}
.bottom_anchor{
  bottom: 0;
}

.example-warper {
  width: 100%;
  padding: 20px 5%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border: 1px #cfcece dashed;
}

.MuiDrawer-paper {
  width: 60% !important;
  }
.MuiGrid-spacing-xs-2 {
  margin: 0px !important;
}
.MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 0px !important;
}

.menu-button {
  background-color: #6C72FF !important;
  max-width: 50px !important;
}

.tip_line {
  display: flex;
  flex-direction: row;
}
.tip_text {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;

  min-width: 50px;
}

.paypal_logo {
  width: 50px !important;
}
.venmo_logo {
  width: 48px !important;
}
.cashapp_logo {
  width: 28px !important;
}
.tip_handle {
  margin-left: 5%;
}
/* .css-6wz749 {
  min-width: 0 !important;
  max-width: 64px !important;

} */


/* .remote_waves {
  bottom: 0;
  left: 0 !important;
  width: 100%;
  height:1em;
} */

.timer_text {
  font-weight: bold;
  font-size: 1em;
}

.kinetic {
  width: 35%;
  height: 35%;
  position: relative;
}

.kinetic::after, .kinetic::before {
  content: '';
  border: 60px solid transparent;
  border-bottom-color: #a1a4ff;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  animation: rotateA 0.6s linear infinite 0.3s;
}

.kinetic::before {
  transform: rotate(90deg);
  animation: rotateB 0.9s linear infinite;
}



@keyframes rotateA {
  0%, 25% {
    transform: rotate(0deg);
  }
  
  50%, 75% {
    transform: rotate(180deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}


@keyframes rotateB {
  0%, 25% {
    transform: rotate(90deg);
  }
  
  50%, 75% {
    transform: rotate(270deg);
  }
  
  100% {
    transform: rotate(450deg);
  }
}


.chart_canvas {
  background-color: white !important;
}

.chip {
  background-color: transparent !important;
  border: 1px solid #6c72ffed !important;
  color: #252636 !important;
}

.list_view_f .chip {
 border: 1px solid #fff !important;
}

.dialog-container {
  display: flex;
  flex-direction: column;
  height: 60%;
  /* padding: 16px; */
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.dialog-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #fff;
  border-radius: 16px;
  border-left: 6px solid #6C72FF;
}

.dialog-title {
  margin-bottom: 16px;
}

.dialog-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  text-align: center;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.pagination-item {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.controls {
  display: flex;
}

.expanded {
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F6F6F6;
  padding: 8px 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-left: auto;
  margin-right: auto;
}

.controls .MuiSvgIcon-root {
  font-size: 36px;
  color: #6C72FF;
  cursor: pointer;
}

.controls .MuiSvgIcon-root:hover {
  color: #888EFF;
}

.controls .Mui-disabled {
  color: #C4C4C4;
  cursor: default;
}



@media only screen and (max-width: 768px) {
  .intro {
    font-size: 1.65em;
  }
  .f_intro {
    font-size: 1.25em;
  }
  .instructions {
    padding-top: 5px;
    padding-bottom: 0px;
    font-size: 1.25em;
  }
  .f_instructions {
    padding-top: 5px;
    padding-bottom: 0px;
    font-size: 1.15em;
  }
  .remote_bar {
    height: 9%;
    min-height: 69px;
  }
  .remote {
    max-height: 20.5vh;
    width: 100%;

    /* margin: 15px; */
  }
  .tabs{
    padding-top: 10.5vh;

  }
  .remote_buttons_div {
    background-color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    max-height: 65%;
    margin-right: 15px;

  }
  .current_art {
    width: 75px;
    height: 75px;
    border-radius: 25px;
    margin-right: 8px;
    margin-left: 8px;
  }
  .list_view {
    max-height: 69vh;
    overflow: auto;
  }
  .queue_list_view {
    max-height: 42vh;
    overflow: auto;
  }

  .MuiDrawer-paper {
    width: 52% !important;
  }

  /*
  .remote_waves {
    height:20px;
    min-height:15px;
  } */
  .dialog-container {
    /* padding: 8px; */
  }
  .dialog-content {
    padding: 16px;
  }
  .pagination-item {
    width: 8px;
    height: 8px;
    margin-right: 4px;
  }
}