$time:20s; // including 1 flip

// body {
// //   height:100vh;
//   margin:0;
// }

.hourglass {
  animation:flip $time ease-in-out infinite;
  border-bottom:solid 1vmin #630;
  border-top:solid 1vmin #630;
//   left:50%;
//   margin-left:-6vmin;
//   margin-top:-11vmin;
  padding:0 1vmin;
  position:fixed;
  width: 70%;
  margin-left: 16px;
//   top:50%;
  .top, .bottom {
    // background-color:#def;
    box-shadow:0 0 1vmin 1vmin #bcd inset;
    height:10vmin;
    overflow:hidden;
    position:relative;
    width:10vmin;
  }
  .top {
    border-radius:0 0 50% 50%;    
    &:before {
      animation:top $time linear infinite;
      background-color:#6C72FF;
      border-radius:50%;
      content:"";
      display:block;
      height:10vmin;
      left:0;
      position:absolute;
      top:0;
      transform:translateY(50%);
      width:10vmin;
    }
    &:after {
      animation:top-drip $time linear infinite;
      background-color:#6C72FF;
      content:"";
      display:block;
      height:100%;
      left:45%;
      position:absolute;
      top:0;
      transform:translateY(100%);
      width:10%;
    }
  }
  .bottom {
    border-radius:50% 50% 0 0;
    &:before {
      animation:bottom $time linear infinite;
      background-color:#6C72FF;
      border-radius:50%;
      content:"";
      display:block;
      height:10vmin;
      left:0;
      position:absolute;
      top:0;
      transform:translateY(100%);
      width:10vmin;
    }
    &:after {
      animation:bottom-drip $time linear infinite;
      background-color:#6C72FF;
      content:"";
      display:block;
      height:100%;
      left:45%;
      position:absolute;
      top:0;
      width:10%;
    }
  }
}

@keyframes flip {
  0%, 45% {
    transform:rotate(0);
  }
  50%, 95% {
    transform:rotate(180deg);
  }
  100% {
    transform:rotate(360deg);
  }
}

@keyframes bottom {
  0% {
    transform:translateY(100%);
  }
  50% {
    transform:translateY(50%);
  }
  51% {
    transform:translateY(-50%);
  }
  100% {
    transform:translateY(-100%);
  }
}

@keyframes top {
  0% {
    transform:translateY(50%);
  }
  50% {
    transform:translateY(100%);
  }
  51% {
    transform:translateY(-100%);
  }
  100% {
    transform:translateY(-50%);
  }
}

@keyframes bottom-drip {
  0% {
    left:45%;
    transform:translateY(-100%);
    width:10%;
  }
  5% {
    transform:translateY(0);
  }
  45%, 100% {
    left:50%;
    transform:translateY(0);
    width:0;
  }
}

@keyframes top-drip {
  0%, 50% {
    left:45%;
    transform:translateY(100%);
    width:10%;
  }
  55% {
    left:45%;
    transform:translateY(0);
    width:10%;
  }
  100% {
    left:50%;
    transform:translateY(0);
    width:0;
  }
}